.photo-hold {
    width: calc(100% - 30px);
    margin-left: 15px;
    color: #555;
}

.photo-hold > h2 {
    margin-top: 5px;
    margin-bottom: 10px;
    color: #555;
}


.static-photo {
    width: 100%;
    object-fit: contain;
    margin-bottom: 10px;
}