
.footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  color: whitesmoke;
  width: calc(100% - 40px);
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  font-family: 'Quicksand', sans-serif;
  font-weight: normal;
  height: auto;
  background-image: url("../img/footer.svg");
  background-size: cover;
  padding: 20px;
  overflow: hidden;
  bottom: 0;
}

.footer__h2 {
  margin: 0;
}

.footer__p {
  margin-top: 0;
}

.footer__left {
  display: flex;
  flex-direction: column;
}

.footer__right {
  display: flex;
}

.footer__gap {
  margin-bottom: 10px;
}

@media only screen and (max-width: 480px) {
  .footer {
    float:none; /* Ensure stacking */
    display:block; /* Ensure stacking */
    font-size: 12px !important;
    padding-left: 12px;
    width: calc(100% - 32px);
  }



  #footer #elements {
    position:relative;
    padding-top: 1rem;  /* Reserve vertical space for phone number */
  }

  #footer #elements p {
    position:absolute; /* Causes the phone number to position above social buttons */
    top:0;
    left:0;
    right:0;
  }
}
