/* ----------- Non-Retina Screens ----------- */
@media only screen and (min-width: 1200px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
    .contact__mid {
        flex-direction: column !important;
    }
}


