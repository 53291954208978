.flower{
    position: absolute;
    bottom: 0;
    right: 5px;
    opacity: 1;
    margin: 0 auto;
    width: 50px;
    transform: rotate(180deg);
}
.stem{
    position: absolute;
    left: 25px;
    width: 5px;
    height: 0px;
    background: linear-gradient(-90deg, rgba(19, 114, 50, 0.3), #137232);
    animation: grow 10s linear forwards;
}
@keyframes grow{
    4%{height: 0;}
    14%{height: 52px;}
    33%{height: 80px;}
    41%{height: 100px;}
    45%{height: 120px;}
    52%{height: 140px;}
    55%{height: 160px;}
    100%{height: 220px;}
}
.leaf{
    position: absolute;
    width: 25px;
    top: 30px;
    left: 16px;
    height: 38px;
    border-radius: 1% 100%;
    background: linear-gradient(70deg, rgba(19, 114, 50, 0.3), rgba(19, 114, 50, 0.5) 36%, #137232 80%, #137232 100%);
    transform-origin: bottom;
    transform: rotate(-110deg);
    animation: leaf-1 10s linear;
}
@keyframes leaf-1{
    0%{transform: scaleY(0) rotate(-180deg);}
    38%{transform: scaleY(0) rotate(-110deg);}
    50%{transform: scaleY(1.1) rotate(-110deg);}
}
.leaf:before{
    position: absolute;
    content: '';
    top: 12px;
    left: -58px;
    width: 30px;
    height: 45px;
    border-radius: 1% 100%;
    background: linear-gradient(65deg, rgba(19, 114, 50, 0.3), rgba(19, 114, 50, 0.5) 36%, #137232 80%, #137232 100%);
    transform: rotate(110deg);
    animation: leaf-2 10s linear;
}
@keyframes leaf-2{
    0%{transform: scaleY(0) rotate(110deg);}
    45%{transform: scaleY(0) rotate(110deg);}
    52%{transform: scaleY(1.1) rotate(110deg);}
}
.leaf:after{
    position: absolute;
    content: '';
    top: -32px;
    left: -96px;
    width: 25px;
    height: 35px;
    border-radius: 1% 100%;
    background: linear-gradient(70deg, rgba(19, 114, 50, 0.3), rgba(19, 114, 50, 0.5) 36%, #137232 80%, #137232 100%);
    transform-origin: bottom;
    animation: leaf-3 10s linear;
}
@keyframes leaf-3{
    0%{transform: scaleY(0);}
    55%{transform: scaleY(0);}
    72%{transform: scaleY(1.1);}
}