.home {
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
}



.home__image {
  display: flex;
  width: 100%;
  margin-bottom: 12px;
}

.home__row {
  display: flex;
  z-index: 1;
  margin-left: 5px;
  margin-right: 5px;
}


.home__rowContainer {
  margin-top: -30px;
}

.home__sliderContainer {
  position: relative;
  height: 370px;
}

.home__logoDisplay {
  position: absolute;
  width: 146px;
  height: 58px;
  object-fit: contain;
  top: 8px;
  margin-left: 12px;
  z-index: 6;
}

.home__trading {
  position: absolute;
  width: 180px;
  height: 30px;
  object-fit: contain;
  top: 22px;
  text-align: right;
  left: 143px;
  color: #fff;
  padding-left: 15px;
  padding-right: 18px;
  border-radius: 0 30px 30px 0;
  margin-left: 0;
  z-index: 5;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-left: none;
  box-shadow: inset 0 -3px 3px 0 #02082f;
  background: linear-gradient(0deg, #122a55 0%, #3C5A95 100%);

}

.home__logoDisplay {
  position: absolute;
  width: 146px;
  height: 58px;
  object-fit: contain;
  top: 8px;
  margin-left: 12px;
  z-index: 6;
}

.home__info {
  padding: 40px;
  font-family: "Slabo", sans-serif;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.44);
}

.home__imageCaption {
  position: absolute;
  width: calc(100% - 10px);
  height: 18px;
  bottom: 12px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 5px;
  font-size: 0.9em;
  color: #ededed;
  background-color: rgba(0, 17, 4, 0.5);
}



