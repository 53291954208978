@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

.header {
  height: 100px;
  display: flex;
  background-color: #fff;
  font-size: 3.5vw;
  align-items: center;
  position: sticky;
  width: 100%;
  top: 0;
  flex: 1;
  justify-content: space-between;
  z-index: 8;
}


.header__logo {
  margin: 12px;
  height: 80px;
  padding-left: 3px;
  padding-top: 5px;
}

.header__prop {
  height: 90px;
  background-color: #f7fff6;;
}



.header__search {
  display: flex;
  flex: 1 1;
  align-items: center;
  border-radius: 24px!important
}

.header__searchInput {
  height: 16px!important;
  padding: 10px;
  border: none;
  width: 90%;
  border-radius: 5px 0 0 5px
}

.header__searchIcon {
  padding: 5px;
  height: 26px!important;
  background-color: #febd69;
  border-radius: 0 5px 5px 0;
  width: 30px!important
}

.header__nav {
  display: flex;
  top: 10px;
  width: 100%;
  z-index: 9;
  height: 28px;
  justify-content: space-evenly;
}

.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color: #333
}

.header__optionLineOne {
  font-size: 10px
}

.header__optionLineTwo {
  font-size: 13px;
  font-weight: 800
}

.header__optionBasket {
  display: flex;
  align-items: center;
  color: #fff;
  margin-top: 10px;
  overflow: hidden
}

.header__basketCount {
  margin-left: 19px;
  margin-right: 10px;
  margin-top: 2px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #febd69
}

.header__title {
  font-size: 3.5vw;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1.2vw;
  color: #064700;
}

.toolbar {
  position: absolute;
  top: 50px;
}

.header__navContain {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.MuiIconButton-label {
  width: 100%;
  display: flex;
  margin-top: -2px;
  align-items: inherit;
  justify-content: inherit;
}





