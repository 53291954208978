@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
* {
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  margin: 0;
}

.app {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Quicksand', sans-serif;
  width: 100%;
}

.app__body {
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 0;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

h1, h2, h3, h4, h5 {
  font-family: Arial, Helvetica, sans-serif;
}

.header {
  height: 100px;
  display: -webkit-flex;
  display: flex;
  background-color: #fff;
  font-size: 3.5vw;
  -webkit-align-items: center;
          align-items: center;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  z-index: 8;
}


.header__logo {
  margin: 12px;
  height: 80px;
  padding-left: 3px;
  padding-top: 5px;
}

.header__prop {
  height: 90px;
  background-color: #f7fff6;;
}



.header__search {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 24px!important
}

.header__searchInput {
  height: 16px!important;
  padding: 10px;
  border: none;
  width: 90%;
  border-radius: 5px 0 0 5px
}

.header__searchIcon {
  padding: 5px;
  height: 26px!important;
  background-color: #febd69;
  border-radius: 0 5px 5px 0;
  width: 30px!important
}

.header__nav {
  display: -webkit-flex;
  display: flex;
  top: 10px;
  width: 100%;
  z-index: 9;
  height: 28px;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.header__option {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color: #333
}

.header__optionLineOne {
  font-size: 10px
}

.header__optionLineTwo {
  font-size: 13px;
  font-weight: 800
}

.header__optionBasket {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  margin-top: 10px;
  overflow: hidden
}

.header__basketCount {
  margin-left: 19px;
  margin-right: 10px;
  margin-top: 2px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #febd69
}

.header__title {
  font-size: 3.5vw;
  width: 100%;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1.2vw;
  color: #064700;
}

.toolbar {
  position: absolute;
  top: 50px;
}

.header__navContain {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.MuiIconButton-label {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  margin-top: -2px;
  -webkit-align-items: inherit;
          align-items: inherit;
  -webkit-justify-content: inherit;
          justify-content: inherit;
}






.home {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: calc(100% - 24px);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
}



.home__image {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin-bottom: 12px;
}

.home__row {
  display: -webkit-flex;
  display: flex;
  z-index: 1;
  margin-left: 5px;
  margin-right: 5px;
}


.home__rowContainer {
  margin-top: -30px;
}

.home__sliderContainer {
  position: relative;
  height: 370px;
}

.home__logoDisplay {
  position: absolute;
  width: 146px;
  height: 58px;
  object-fit: contain;
  top: 8px;
  margin-left: 12px;
  z-index: 6;
}

.home__trading {
  position: absolute;
  width: 180px;
  height: 30px;
  object-fit: contain;
  top: 22px;
  text-align: right;
  left: 143px;
  color: #fff;
  padding-left: 15px;
  padding-right: 18px;
  border-radius: 0 30px 30px 0;
  margin-left: 0;
  z-index: 5;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-left: none;
  box-shadow: inset 0 -3px 3px 0 #02082f;
  background: linear-gradient(0deg, #122a55 0%, #3C5A95 100%);

}

.home__logoDisplay {
  position: absolute;
  width: 146px;
  height: 58px;
  object-fit: contain;
  top: 8px;
  margin-left: 12px;
  z-index: 6;
}

.home__info {
  padding: 40px;
  font-family: "Slabo", sans-serif;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.44);
}

.home__imageCaption {
  position: absolute;
  width: calc(100% - 10px);
  height: 18px;
  bottom: 12px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 5px;
  font-size: 0.9em;
  color: #ededed;
  background-color: rgba(0, 17, 4, 0.5);
}




/* ----------- Non-Retina Screens ----------- */
@media only screen and (min-width: 1200px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
    .contact__mid {
        -webkit-flex-direction: column !important;
                flex-direction: column !important;
    }
}




.footer{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #fff;
  color: whitesmoke;
  width: calc(100% - 40px);
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  font-family: 'Quicksand', sans-serif;
  font-weight: normal;
  height: auto;
  background-image: url(/static/media/footer.84bd044a.svg);
  background-size: cover;
  padding: 20px;
  overflow: hidden;
  bottom: 0;
}

.footer__h2 {
  margin: 0;
}

.footer__p {
  margin-top: 0;
}

.footer__left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.footer__right {
  display: -webkit-flex;
  display: flex;
}

.footer__gap {
  margin-bottom: 10px;
}

@media only screen and (max-width: 480px) {
  .footer {
    float:none; /* Ensure stacking */
    display:block; /* Ensure stacking */
    font-size: 12px !important;
    padding-left: 12px;
    width: calc(100% - 32px);
  }



  #footer #elements {
    position:relative;
    padding-top: 1rem;  /* Reserve vertical space for phone number */
  }

  #footer #elements p {
    position:absolute; /* Causes the phone number to position above social buttons */
    top:0;
    left:0;
    right:0;
  }
}

.container {
    color: #555;
}

.contact__title {
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contact__mid {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
}

.contact__left, .contact__right {
    background-color: #fff;
    padding: 5px;
    width: calc(100% - 10px);
}

.contact__inner {
    border: 1px solid #137232;
    padding: 3px;
    color: #4c5872;
    font-size: 18px;
}

.margin-bottom {
    margin-bottom: 10px !important;
}

.form-submit {
    margin-bottom: 5px;
    margin-top: 10px;
}

.contact__addressContainer {
    position: relative;
    padding: 7px;
    overflow: hidden;
}

.contact__addressContainer > h4 {
    margin-bottom: 5px;
}

.contact__addressContainer > h3 {
    margin-bottom: 4px;
}

.contact__phone {
    margin-top: 10px;
    margin-bottom: 2px !important;
}

.contact__email {
    margin-bottom: 5px;
    margin-top: 0;
}

.MuiButton-containedPrimary {
    background-color: #FFF !important;
    color: #333 !important;
}

.contact__marginTwelve {
    margin-bottom: 17px !important;
}

.MuiFilledInput-root {
    background-color: rgba(19, 114, 50, 0.07) !important;
}

 .MuiFilledInput-underline:after {
     border-bottom: 2px solid #137232 !important;
 }

 .MuiIconButton-root {
     padding: 6px !important;
 }

@media only screen and (max-width: 480px) {
    .contact__inner {

        font-size: 17px !important;

    }
}
.flower{
    position: absolute;
    bottom: 0;
    right: 5px;
    opacity: 1;
    margin: 0 auto;
    width: 50px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.stem{
    position: absolute;
    left: 25px;
    width: 5px;
    height: 0px;
    background: linear-gradient(-90deg, rgba(19, 114, 50, 0.3), #137232);
    -webkit-animation: grow 10s linear forwards;
            animation: grow 10s linear forwards;
}
@-webkit-keyframes grow{
    4%{height: 0;}
    14%{height: 52px;}
    33%{height: 80px;}
    41%{height: 100px;}
    45%{height: 120px;}
    52%{height: 140px;}
    55%{height: 160px;}
    100%{height: 220px;}
}
@keyframes grow{
    4%{height: 0;}
    14%{height: 52px;}
    33%{height: 80px;}
    41%{height: 100px;}
    45%{height: 120px;}
    52%{height: 140px;}
    55%{height: 160px;}
    100%{height: 220px;}
}
.leaf{
    position: absolute;
    width: 25px;
    top: 30px;
    left: 16px;
    height: 38px;
    border-radius: 1% 100%;
    background: linear-gradient(70deg, rgba(19, 114, 50, 0.3), rgba(19, 114, 50, 0.5) 36%, #137232 80%, #137232 100%);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    -webkit-transform: rotate(-110deg);
            transform: rotate(-110deg);
    -webkit-animation: leaf-1 10s linear;
            animation: leaf-1 10s linear;
}
@-webkit-keyframes leaf-1{
    0%{-webkit-transform: scaleY(0) rotate(-180deg);transform: scaleY(0) rotate(-180deg);}
    38%{-webkit-transform: scaleY(0) rotate(-110deg);transform: scaleY(0) rotate(-110deg);}
    50%{-webkit-transform: scaleY(1.1) rotate(-110deg);transform: scaleY(1.1) rotate(-110deg);}
}
@keyframes leaf-1{
    0%{-webkit-transform: scaleY(0) rotate(-180deg);transform: scaleY(0) rotate(-180deg);}
    38%{-webkit-transform: scaleY(0) rotate(-110deg);transform: scaleY(0) rotate(-110deg);}
    50%{-webkit-transform: scaleY(1.1) rotate(-110deg);transform: scaleY(1.1) rotate(-110deg);}
}
.leaf:before{
    position: absolute;
    content: '';
    top: 12px;
    left: -58px;
    width: 30px;
    height: 45px;
    border-radius: 1% 100%;
    background: linear-gradient(65deg, rgba(19, 114, 50, 0.3), rgba(19, 114, 50, 0.5) 36%, #137232 80%, #137232 100%);
    -webkit-transform: rotate(110deg);
            transform: rotate(110deg);
    -webkit-animation: leaf-2 10s linear;
            animation: leaf-2 10s linear;
}
@-webkit-keyframes leaf-2{
    0%{-webkit-transform: scaleY(0) rotate(110deg);transform: scaleY(0) rotate(110deg);}
    45%{-webkit-transform: scaleY(0) rotate(110deg);transform: scaleY(0) rotate(110deg);}
    52%{-webkit-transform: scaleY(1.1) rotate(110deg);transform: scaleY(1.1) rotate(110deg);}
}
@keyframes leaf-2{
    0%{-webkit-transform: scaleY(0) rotate(110deg);transform: scaleY(0) rotate(110deg);}
    45%{-webkit-transform: scaleY(0) rotate(110deg);transform: scaleY(0) rotate(110deg);}
    52%{-webkit-transform: scaleY(1.1) rotate(110deg);transform: scaleY(1.1) rotate(110deg);}
}
.leaf:after{
    position: absolute;
    content: '';
    top: -32px;
    left: -96px;
    width: 25px;
    height: 35px;
    border-radius: 1% 100%;
    background: linear-gradient(70deg, rgba(19, 114, 50, 0.3), rgba(19, 114, 50, 0.5) 36%, #137232 80%, #137232 100%);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    -webkit-animation: leaf-3 10s linear;
            animation: leaf-3 10s linear;
}
@-webkit-keyframes leaf-3{
    0%{-webkit-transform: scaleY(0);transform: scaleY(0);}
    55%{-webkit-transform: scaleY(0);transform: scaleY(0);}
    72%{-webkit-transform: scaleY(1.1);transform: scaleY(1.1);}
}
@keyframes leaf-3{
    0%{-webkit-transform: scaleY(0);transform: scaleY(0);}
    55%{-webkit-transform: scaleY(0);transform: scaleY(0);}
    72%{-webkit-transform: scaleY(1.1);transform: scaleY(1.1);}
}
.about {
    margin: 15px;
}

.about > h3 {
    margin-bottom: 10px;
    color: #333
}

.photo-hold {
    width: calc(100% - 30px);
    margin-left: 15px;
    color: #555;
}

.photo-hold > h2 {
    margin-top: 5px;
    margin-bottom: 10px;
    color: #555;
}


.static-photo {
    width: 100%;
    object-fit: contain;
    margin-bottom: 10px;
}
