.container {
    color: #555;
}

.contact__title {
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contact__mid {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
}

.contact__left, .contact__right {
    background-color: #fff;
    padding: 5px;
    width: calc(100% - 10px);
}

.contact__inner {
    border: 1px solid #137232;
    padding: 3px;
    color: #4c5872;
    font-size: 18px;
}

.margin-bottom {
    margin-bottom: 10px !important;
}

.form-submit {
    margin-bottom: 5px;
    margin-top: 10px;
}

.contact__addressContainer {
    position: relative;
    padding: 7px;
    overflow: hidden;
}

.contact__addressContainer > h4 {
    margin-bottom: 5px;
}

.contact__addressContainer > h3 {
    margin-bottom: 4px;
}

.contact__phone {
    margin-top: 10px;
    margin-bottom: 2px !important;
}

.contact__email {
    margin-bottom: 5px;
    margin-top: 0;
}

.MuiButton-containedPrimary {
    background-color: #FFF !important;
    color: #333 !important;
}

.contact__marginTwelve {
    margin-bottom: 17px !important;
}

.MuiFilledInput-root {
    background-color: rgba(19, 114, 50, 0.07) !important;
}

 .MuiFilledInput-underline:after {
     border-bottom: 2px solid #137232 !important;
 }

 .MuiIconButton-root {
     padding: 6px !important;
 }

@media only screen and (max-width: 480px) {
    .contact__inner {

        font-size: 17px !important;

    }
}