* {
  margin: 0;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Quicksand', sans-serif;
  width: 100%;
}

.app__body {
  width: 100%;
  flex-direction: column;
  margin-top: 0;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

h1, h2, h3, h4, h5 {
  font-family: Arial, Helvetica, sans-serif;
}
